<template>
    <div class="breadcrumb-area breadcrumb-height" data-bg-image="" :style="{backgroundImage: `url(${ detailBg })`}">
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-lg-12">
                    <div class="breadcrumb-item">
                        <h1 class="breadcrumb-detail">{{ breadcrumbTitle }}</h1>
                        <h6 class="breadcrumb-title">{{  }}</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="display-flex row" ref="blogDetail">
            <div class="col-lg-10 col-md-12">
                <div class="content">
                    <div class="row paragraph">
                        <p v-html="text"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <video-background
    src="images/banner/banner-section-top.mp4"
    style="width: 100%;"
    object-fit="fill"
    :style="{  height: '700px' }"
    v-if="breadcrumbName != 'terms_of_use'"
    class="hero-area hero-bg hero-style-1 hero-width desktop"
    >
    </video-background>
    <div style="background-color: #F3F6FF;"
    :style="{  height: 'calc( ' + text.length + ' * 0.33px )' }"
    v-if="breadcrumbName != 'terms_of_use'"
    class="hero-area hero-bg hero-style-1 hero-width desktop">
    </div>
    <video-background
    src="images/banner/banner-section-bot.mp4"
    style="width: 100%;"
    object-fit="fill"
    :style="{  height: '700px' }"
    v-if="breadcrumbName != 'terms_of_use'"
    class="hero-area hero-bg hero-style-1 hero-width desktop"
    >
    </video-background>
    <video-background
    src="images/banner/banner-section-top.mp4"
    style="width: 100%;"
    object-fit="fill"
    :style="{  height: '700px' }"
    v-if="breadcrumbName == 'terms_of_use'"
    class="hero-area hero-bg hero-style-1 hero-width desktop"
    >
    </video-background>
    <div style="background-color: #F3F6FF;"
    :style="{  height: 'calc( ' + text.length + ' * 0.31px )' }"
    v-if="breadcrumbName == 'terms_of_use'"
    class="hero-area hero-bg hero-style-1 hero-width desktop">
    </div>
    <video-background
    src="images/banner/banner-section-bot.mp4"
    style="width: 100%;"
    object-fit="fill"
    :style="{  height: '700px' }"
    v-if="breadcrumbName == 'terms_of_use'"
    class="hero-area hero-bg hero-style-1 hero-width desktop"
    >
    </video-background>
    <video-background
    src="images/banner/banner-section-top.mp4"
    style="width: 100%;"
    object-fit="fill"
    :style="{  height: '700px' }"
    class="hero-area hero-bg hero-style-1 hero-width mobile"
    >
    </video-background>
    <div style="background-color: #F3F6FF;"
    :style="{  height: 'calc( ' + text.length + ' * 0.54px )' }"
    class="hero-area hero-bg hero-style-1 hero-width mobile">
    </div>
    <video-background
    src="images/banner/banner-section-bot.mp4"
    style="width: 100%;"
    object-fit="fill"
    :style="{  height: '700px' }"
    class="hero-area hero-bg hero-style-1 hero-width mobile"
    >
    </video-background>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'
export default {
    components: {
        VideoBackground
    },
    props: ['breadcrumbName', 'breadcrumbTitle', 'breadcrumbDetail'],
    data () {
        return {
            introBg: 'images/breadcrumb/bg/bg.png',
            detailBg: 'images/breadcrumb/bg/detail-bg2.png',
            imageUrl: 'https://dev.api.the-netwerk.com/storage/app/',
            text: '',
            data: [],
        }
    },
    computed: {
        textData(){
            return this.data.filter(item => item.type == this.breadcrumbName)
        }
    },
    async mounted () {
        await this.getData()
    },
    methods: {
        async getData() {
            await this.axios.get(`https://dev.api.the-netwerk.com/api/RisetAI/H1wWlOLOSzW6F4HjLC1K/information`).then(res => {
                this.data = res.data
                // Foreach Data
                res.data.forEach(item => {
                    if (item.type == this.breadcrumbName) {
                        this.text = item.text
                    }
                })
            })
        },
    }
}
</script>
<style scoped>
.breadcrumb-area {
    background-color: #0D0D2D;
}
.hero-width{
    width: 100%;
    display: flex;
}
.ql-align-center {
    text-align: center;
}
.breadcrumb-title {
    color: #ffffff;
    font-size: 20px;
    line-height: 20px;
    margin-top: 8px;
}
.breadcrumb-detail {
    color: #ffffff;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    /* or 150% */
    margin-block: 1px;

    text-align: center;
    letter-spacing: 0.03em;
}
.btn.purple-color {
    background-color: transparent;
    color: #000000;
    border-style: solid;
    border-color: rgb(39, 39, 39);
    font-family: "Gordita";
    width: fit-content;
    font-size: 12px;
    border-radius: 6px;
    padding: 20px 20px;
    line-height: 0px;
}

.display-flex {
    height: 0px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin-top: -50px;
}

.content {
    background-color: white;
}

.display-flex div {
    padding: 12px 28px;
    z-index: 10;
    border-radius: 12px;
}

.display-flex span {
    padding: 15px 15px;
}

.paragraph p{
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    /* or 160% */

    text-align: justify;
    align-items: center;
    text-indent: 24px;
    white-space: pre-wrap;
}

.paragraph img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    height: auto;
    margin: 30px auto;
}
.testimonial-content img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 0px;
}
.section-desc {
    color: #8D1245;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 42px;
    max-width: 610px;
    margin-bottom: 0px;
    margin-top: 40px;
}
.user-title {
    text-align: left;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
}
.screen-container {
    align-items: center;
    text-align: center;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
}
.section-title {
    color: #25376F;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 800;
    font-size: 38px;
    line-height: 58px;
    text-align: center;
    letter-spacing: 0.03em;
}
.testimonial-item-2 {
    display: flex;
    width: 100%;
    padding: 0px 0px !important;
}
.testimonial-user-info .user-content {
    margin-left: 0px;
}
.testimonial-user-info {
    margin-top: 0px;
}
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media only screen and (max-width: 991px) {
    .breadcrumb-title {
        font-size: 16px;
    }

    .hero-width{
        width: 100%;
    }

    .mobile {
        display: block;
    }
    
    .desktop {
        display: none;
    }

    .breadcrumb-height {
        height: 550px;
    }

    .display-flex {
        height: 0px;
        width: 90%;
        display: flex;
        margin-top: -40px;
        margin-left: 5%;
        margin-right: 5%;
    }
    
    .display-flex div {
        text-align: center;
        padding: 6px 8px;
        z-index: 10;
        border-radius: 12px;
    }
    
    .display-flex img {
        width: 30%;
        padding: 5px 5px;
    }
    .feature-icon img{
        width: 100%;
    }
}
</style>
